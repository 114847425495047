import { useEffect, useState, useMemo } from "react";
import { createPortal } from "react-dom";
import {
	Link,
	useNavigate,
	useParams,
	Outlet,
	useRouteLoaderData,
} from "react-router-dom";
import { ComunaAPI } from "../managers/ComunaAPI";

import { capitalizeWords, FormatLink } from "../utils/formaters";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import MessagesComponent from "./MessagesComponent";
import TechnicalInterviewComponent from "./TechnicalInterview/TechnicalInterviewComponent";
import NewTechnicalInterviewComponent from "./TechnicalInterview/TechnicalInterviewComponent_V1.1";

import { ApplicationStatusMessage } from "./ApplicationStatusMessage";
import SalaryBadge from "./SalaryBadge";
import { Loader } from "./loader";

const linkedin_icon = require("../img/icons/linkedin-icon.png");
const web_icon = require("../img/icons/web-icon.png");
const hot_candidate_icon = require("../img/icons/hot_candidate.png");
const resume_icon = require("../img/icons/resume-icon.png");
const clock_icon = require("../img/icons/clock-icon.png");
const text_bubble_icon = require("../img/icons/message-text-square-01.png");
const coins_stacked_icon = require("../img/icons/coins-stacked-02.png");
const sliders_icon = require("../img/icons/sliders-03.png");
const xIcon = require("../img/icons/x.png");
const pie_chart_icon = require("../img/icons/pie-chart-purple.png");
const receipt_icon = require("../img/icons/receipt-purple.png");

const coins_hand_icon = require("../img/icons/coins-hand-green.png");

const juniorBadge = require("../img/icons/junior-badge.png");
const midBadge = require("../img/icons/mid-badge.png");
const seniorBadge = require("../img/icons/senior-badge.png");
const leadBadge = require("../img/icons/lead-badge.png");

export function ClientApplicationDetailView() {
	const { application_id, request_id }: any = useParams();
	const { resReqFields, resourceRequests, organizationDetails }: any = useRouteLoaderData("hiring");

	const r = resourceRequests.filter((item: any) => item.id === parseInt(request_id));
	const app = r && r[0] ? r[0].applications.filter((app: any) => app.id === parseInt(application_id)) : null;

	const [resourceRequest, setRequest] = useState<any>(r[0]);
	const [application, setApplication] = useState<any>(app[0]);
	const [interviewDetails, setInterviewDetails] = useState<any>(null);

	useEffect(() => {
		const fetchInterviewDetails = async () => {
			const token = await LocalStorageManager.getToken();
			try {
				const interviewData = await ComunaAPI.GetInterview(token, application.interview.id);
				setInterviewDetails(interviewData);
			} catch (error) {
				console.error('Failed to fetch interview:', error);
			}
		};
		if (application.interview) {
			fetchInterviewDetails();
		}
	}, [application_id]);

	const [userResReqReturn, setUserResReqReturn] = useState<any>(resReqFields);

	const determineInitialTab = () => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('interview')) return 'interview';
		if (searchParams.has('messages')) return 'messages';
		if (searchParams.has('profile')) return 'profile';

		if (interviewDetails) {
			return 'interview';
		}
		return 'profile';
	};

	const [selectedTab, setSelectedTab] = useState(determineInitialTab()); // Set the initial tab
	const [canAskForInterview, setCanAskForInterview] = useState<boolean>(
		application.interview_calendar_link === null && application.interview_available_dates === null
	);

	const [seeCosts, setSeeCosts] = useState<boolean | undefined>(false);

	const [categories, setCategories] = useState([]);
	const [availableBenefits, setAvailableBenefits] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		let url = new URL(window.location.href);
		let searchParams = url.searchParams;

		// Remove existing parameters to avoid duplicates or incorrect values
		searchParams.delete('profile');
		searchParams.delete('messages');
		searchParams.delete('interview');

		// Add new parameter based on the selected tab
		switch (selectedTab) {
			case 'profile':
				searchParams.append('profile', 'true');
				break;
			case 'messages':
				searchParams.append('messages', 'true');
				break;
			case 'interview':
				if (application.interview) {
					searchParams.append('interview', 'true');
					if (!application.interview.marked_view) {
						markInteviewViewed();
					}
				}
				break;
			default:
				break;
		}
		// Update the URL with the new parameters without reloading the page
		window.history.replaceState(null, '', `${url.pathname}?${searchParams.toString()}`);
	}, [selectedTab]);

	async function markInteviewViewed() {
		if (!application.interview) return;

		var token = await LocalStorageManager.getToken();
		try {
			const result = await ComunaAPI.MakeInterviewViewed(token, application.interview.id);
			application.interview.marked_view = true;
			console.log('Success:', result);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	useEffect(() => {
		if (userResReqReturn !== undefined && userResReqReturn !== null) {
			console.log(userResReqReturn);
			setCategoriesAndBenefits();
		}
	}, [userResReqReturn]);

	function setCategoriesAndBenefits() {
		setCategories(userResReqReturn.benefits_categories);
		setAvailableBenefits(userResReqReturn.benefits);
	}

	const proposalExists = () => {
		return application.proposal !== null && application.proposal !== undefined;
	};

	const GetOneTimeBenefitsCost = () => {
		var total = 0;
		availableBenefits.map((benefit: any) => {
			application.proposal.benefits.map((subBenefit: any) => {
				if (benefit.id === subBenefit.id && benefit.one_time_only) {
					total += benefit.cost;
				}
			});
		});
		return total;
	};

	const monthlyBenefitsCost = useMemo(() => {
		if (!application?.proposal) return 0;
		var total = 0;
		availableBenefits.map((benefit: any) => {
			application.proposal.benefits.map((subBenefit: any) => {
				if (subBenefit.id === benefit.id && !benefit.one_time_only) {
					total += benefit.cost;
				}
			});
		});
		console.log("Monthly benefits", total);
		return total;
	}, [application, availableBenefits]);

	//this should be a dynamic percentage loaded from the backend?
	const GetPlatformFee = () => {
		const benefits = GetOneTimeBenefitsCost() + monthlyBenefitsCost * 12;
		const total = benefits + application.proposal.yearly_salary;
		let fee;

		if (organizationDetails.custom_comuna_fee === -1) {
			fee = total * 0.2;
		} else if (organizationDetails.fixed_comuna_fee) {
			fee = organizationDetails.custom_comuna_fee * 12;
		} else {
			fee = total * (organizationDetails.custom_comuna_fee / 100);
		}
		return fee;
	};

	//we are assuming a 2.5% fee of platform
	const GetGatewayFee = () => {
		const benefits = GetOneTimeBenefitsCost() + monthlyBenefitsCost * 12;
		const comuna_fee = GetPlatformFee();
		const total = benefits + application.proposal.yearly_salary + comuna_fee;
		var gateway_fee = total * 0.029 + 0.3;
		return gateway_fee;
	};

	//return the yearly contract cost.
	function GetTotalContractCost() {
		const benefits = GetOneTimeBenefitsCost() + monthlyBenefitsCost * 12;
		const comuna_fee = GetPlatformFee();
		const gateway_fee = GetGatewayFee(); //gateway fee is not gonna be show at this stage
		const total = benefits + application.proposal.yearly_salary + comuna_fee;
		return total;
	}

	function StateBadge(): any {
		if (application.proposal != null && application.proposal.state === 1) {
			return <div className="green-badge big">Hired</div>;
		}

		if (application.proposal != null && application.proposal.state === 3) {
			return <div className="red-badge big">Rejected</div>;
		}

		if (application.state === 0) {
			return <div className="blue-badge big mr-2">Screening Candidate</div>;
		}
		if (application.state === 1) {
			return (
				<div className="orange-badge big mr-2">Cultural Fit Interview</div>
			);
		}
		if (application.state === 2) {
			return <div className="yellow-badge big mr-2">Technical Interview</div>;
		}
		if (application.state === 3) {
			return <div className="purple-badge big mr-2">Partner Interview</div>;
		}
		if (application.state === 4) {
			return <div className="blue-badge big mr-2">Offer Sent</div>;
		}

		//if hired
		if (application.state === 5) {
			return <div className="green-badge big mr-2">Hired</div>;
		}

		if (application.state === 6) {
			return <div className="red-badge big mr-2">Rejected</div>;
		}
	}

	function InterviewBadge() {
		//if hired
		const proposalSent = application.state >= 4;

		//if is past the cultural interview stage
		const canAskForInterview = application.state > 1 && application.state !== 5;
		//was the interview requested?
		const interviewRequested =
			application.interview_available_dates != null ||
			application.interview_calendar_link != null;
		//is the interview scheduled?
		const interviewScheduled = application.interview_call_link != null;

		if (interviewScheduled) {
			return (
				<div className="blue-badge big flex flex-row mr-2 items-center">
					<img
						style={{
							width: 16,
							height: 16,
							bottom: 10,
							right: -10,
						}}
						src={clock_icon}
						alt="Fire icon"
						className="mr-1"
					/>
					Interview scheduled
				</div>
			);
		}

		if (interviewRequested) {
			return (
				<div className="blue-badge big flex flex-row mr-2 items-center">
					<img
						style={{
							width: 16,
							height: 16,
							bottom: 10,
							right: -10,
						}}
						src={clock_icon}
						alt="Fire icon"
						className="mr-1"
					/>
					Interview requested
				</div>
			);
		}

		return <></>;
	}

	function CanShowActions() {
		//if the application is rejected we cannot show actions
		if (application.state === 6) {
			return false;
		}
		//if the application is hired we cannot show actions
		if (
			application.state === 5 ||
			(application.proposal != null && application.proposal.state === 1)
		) {
			return false;
		}

		//if the application status is less than technical interview we cannot show actions
		if (application.state < 2) {
			return false;
		}

		//other wise we can show actions
		return true;
	}

	function Skills() {
		return (
			<div className="flex flex-row flex-wrap mt-2">
				{application !== undefined &&
					application.contractor.skills.map((skill: any, i: number) => {
						return (
							<span key={i} className="skill-badge tooltip mb-2 mr-2">
								{skill}
							</span>
						);
					})}
			</div>
		);
	}

	function Close() {
		var index = window.location.pathname.indexOf("/application");
		var target = window.location.pathname.substring(0, index);
		navigate(target);
	}

	if (resourceRequest === null || application == null) {
		return (<div>NO REQUEST INFO</div>);
	}

	const GetHourlySalary = () => {
		// TODO
		// DIVIDED BY 2 BECAUSE OF RECENT FIX. REMOVE LATER
		const hourlySalary = application.salary_recommendation / 12 / 160 / 2;
		return Math.round(hourlySalary).toLocaleString();
	}

	function Profile() {
		return (
			<>
				{/** Close Button */}
				<button
					onClick={Close}
					className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					{/* <img src={xIcon} alt="icon of an x" /> */}
				</button>

				<ApplicationStatusMessage application={application} />

				<div className="flex flex-row justify-between items-center bg-purple-light mb-3 rounded-lg p-4 mt-8">
					<div className="flex flex-row items-center">
						{/**Profile Picture */}
						<div className="relative">
							{application.contractor.profile_picture && (
								<img
									src={application.contractor.profile_picture}
									alt=""
									style={{ height: 140, width: 140, borderRadius: "50%" }}
								/>
							)}
							{!application.contractor.profile_picture && (
								<div
									className="bg-purple-light flex justify-center items-center font-bold comuna-purple text-4xl"
									style={{
										height: 84,
										width: 84,
										borderRadius: "50%",
										lineHeight: -10,
									}}>
									{application.contractor.first_name.charAt(0).toUpperCase() +
										application.contractor.last_name.charAt(0).toUpperCase()}
								</div>
							)}
						</div>

						{/**Name Title and badges */}
						<div className="flex flex-col ml-4 items-start w-fit">
							<h3 style={{ height: 31 }} className="font-semibold black-text">
								{application.contractor.first_name +
									" " +
									application.contractor.last_name}
							</h3>
							<span className="mb-2">
								{application.contractor.title} -{" "}
								{application.contractor.country_location}
							</span>
							<div className="flex flex-row items-center w-fit">
								<StateBadge />
								<InterviewBadge />
								{application.contractor.top_candidate && (
									<div className="flex flex-row items-center yellow-badge big">
										<img
											style={{
												width: 16,
												height: 16,
												bottom: 10,
												right: -10,
											}}
											src={hot_candidate_icon}
											alt="Fire icon"
										/>
										Hot Candidate
									</div>
								)}
							</div>
						</div>
					</div>
					{/**Quick Actions Buttons */}
					<div className="flex flex-row items-start justify-end flex-1">
						{application.contractor.website && application.contractor.website !== "null" && (
							<a
								onClick={(e) => {
									e.stopPropagation();
								}}
								target="_new"
								className="comuna-circle-button"
								href={FormatLink(application.contractor.website)}>
								<img
									src={web_icon}
									alt="Button to visit website of the candidate"
								/>
							</a>
						)}

						{application.contractor.linkedin && application.contractor.linkedin !== "null" && (
							<a
								onClick={(e) => {
									e.stopPropagation();
								}}
								target="_new"
								className="comuna-circle-button"
								href={FormatLink(application.contractor.linkedin)}>
								<img
									src={linkedin_icon}
									alt="Button to go to linkedin of the candidate"
								/>
							</a>
						)}

						{application.contractor.cv && application.contractor.cv !== "null" && (
							<a
								onClick={(e) => {
									e.stopPropagation();
								}}
								target="_new"
								className="comuna-circle-button"
								href={FormatLink(application.contractor.cv)}>
								<img src={resume_icon} alt="Button to download resume" />
							</a>
						)}
					</div>
				</div>
				<div className="flex flex-row mb-3 w-full">
					{application.contractor.seniority === 1 && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
							<img
								src={juniorBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>1-3 years of experience</b>
						</div>
					)}

					{application.contractor.seniority === 2 && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
							<img
								src={midBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>3-6 years of experience</b>
						</div>
					)}

					{application.contractor.seniority === 3 && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
							<img
								src={seniorBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>{"6-10 years of experience"}</b>
						</div>
					)}

					{application.contractor.seniority === 4 && (
						<div className="bg-purple-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-row justify-center items-center">
							<img
								src={leadBadge}
								alt="four triangles indicating level"
								style={{ height: 24, marginRight: 12 }}
							/>
							<b>10+ years of experience</b>
						</div>
					)}

					<div className="bg-purple-light mb-2 rounded-lg p-4  w-full justify-center flex flex-row tooltip">
						<img
							src={text_bubble_icon}
							alt="text bubble icon"
							style={{ width: 24, height: 24, marginRight: 8 }}
						/>
						<b>{"Fluent english"}</b>
						<b className="tooltiptext" style={{ top: "-50%" }}>
							English fluency
						</b>
					</div>
				</div>
				<div className="flex flex-row mb-3 w-full">
					{/* salary Recommendation */}
					<div className="bg-comuna-blue-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-col">
						<img
							src={sliders_icon}
							alt="four triangles indicating level"
							style={{ height: 24, width: 24, marginRight: 12 }}
						/>
						<label className="text-sm">
							{organizationDetails.direct_contract ? "Hourly rate for similar profiles in the U.S." : "Salary for similar profiles in the U.S."}
						</label>
						<b>
							{organizationDetails.currency.symbol}
							{organizationDetails.direct_contract ? GetHourlySalary() : application.salary_recommendation.toLocaleString()}
							{" "}{organizationDetails.currency.code}
						</b>
					</div>

					{/* what the candidate wants */}
					{!organizationDetails.direct_contract && (
						<div className="bg-comuna-yellow-light mb-2 rounded-lg p-4 w-full flex flex-col relative">
							<div className="flex flex-row w-full justify-between">
								<img
									src={coins_stacked_icon}
									alt="four triangles indicating level"
									style={{ height: 24, width: 24, marginRight: 12 }}
								/>
							</div>
							<label className="text-sm">
								What {capitalizeWords(application.contractor.first_name)} wants
							</label>
							<b>
								{organizationDetails.currency.symbol}
								{application.contractor.salary_expectation.toLocaleString()}
								{" "} {organizationDetails.currency.code}
							</b>
							<SalaryBadge
								style={{ position: "absolute", top: 10, right: 10 }}
								salary={application.contractor.salary_expectation}
								recommendation={application.salary_recommendation}
							/>
						</div>
					)}
				</div>

				{/* offer and costs */}
				{application.proposal !== null &&
					application.proposal !== undefined && (
						<div className="flex flex-row mb-3 w-full">
							{/* Offer */}
							<div className="bg-comuna-green-light mb-2 rounded-lg p-4 mr-4 w-full flex flex-col">
								<img
									src={coins_hand_icon}
									alt="four triangles indicating level"
									style={{ height: 24, width: 24, marginRight: 12 }}
								/>
								<label className="text-sm comuna-dark-green-text">
									Your offer (Salary + Benefits)
								</label>
								<b className="comuna-dark-green-text">
									{organizationDetails.currency.symbol}
									{(
										application.proposal.yearly_salary +
										GetOneTimeBenefitsCost() +
										monthlyBenefitsCost
									)
										.toLocaleString()
										.split(".")[0]}
									{" "}
								</b>
							</div>
							{/* what the candidate wants */}
							<div
								className="hover-card bg-comuna-purple-tab-light mb-2 rounded-lg p-4 w-full flex flex-col"
								onClick={() => {
									setSeeCosts(true);
								}}>
								<div className="flex flex-row w-full justify-between">
									<img
										src={receipt_icon}
										alt="four triangles indicating level"
										style={{ height: 24, width: 24, marginRight: 12 }}
									/>
									<img
										src={pie_chart_icon}
										alt="four triangles indicating level"
										style={{ height: 24, width: 24, marginRight: 12 }}
									/>
								</div>
								<label className="text-sm comuna-purple-text">
									Final cost (Salary + Benefits + Taxes + MUNA fee)
								</label>
								<b className="comuna-purple-text">
									{organizationDetails.currency.symbol}
									{GetTotalContractCost().toLocaleString().split(".")[0]}
									{" "}
								</b>
							</div>
						</div>
					)}
				<div className="w-full flex flex-col py-2 ">
					<b className="text-sm font-semibold">Skills</b>
					<Skills />
				</div>

				<div className="flex flex-col mb-3 w-full mt-5">
					<b className="text-sm font-semibold">Notes from MUNA</b>
					<div className="bg-purple-light mb-2 mt-2 rounded-lg p-4 mr-4 w-full flex flex-col">
						<p className="text-left black-text">
							{application.contractor.note_for_client}
						</p>
					</div>
				</div>

				{seeCosts &&
					createPortal(
						<div
							className="comuna-popup "
							onClick={() => {
								setSeeCosts(false);
							}}>
							<div
								className="comuna-card p-8 w-full text-center relative"
								style={{ maxWidth: 620 }}>
								<button
									onClick={() => {
										setSeeCosts(false);
									}}
									style={{ top: 16, right: 16, position: "absolute" }}>
									<img src={xIcon} alt="close icon" style={{ width: 19 }} />
								</button>
								<div className="flex flex-col justify-between">
									<label className="text-2xl font-bold text-center">
										Offer cost summary
									</label>
									<div className="flex flex-col justify-between items-center bg-purple-light mb-3 rounded-lg p-4 mt-8">
										<div className="flex flex-row w-full justify-between">
											<label className="text-sm">Yearly salary*</label>
											<label className="text-sm">
												{organizationDetails.currency.symbol}
												{application.proposal.yearly_salary}
											</label>
										</div>
										<div className="flex flex-row w-full justify-between">
											<label className="text-sm">One time benefits</label>
											<label className="text-sm">
												{organizationDetails.currency.symbol}
												{GetOneTimeBenefitsCost().toLocaleString()}
											</label>
										</div>
										<div className="flex flex-row w-full justify-between">
											<label className="text-sm">Monthly benefits</label>
											<label className="text-sm">
												{organizationDetails.currency.symbol}
												{monthlyBenefitsCost.toLocaleString().split(".")[0]}
											</label>
										</div>
										<div className="flex flex-row w-full justify-between">
											<label className="text-sm">Platform fee</label>
											<label className="text-sm">
												{organizationDetails.currency.symbol}
												{GetPlatformFee().toLocaleString()}
											</label>
										</div>
									</div>
								</div>

								<button
									onClick={() => {
										// setEditOffer(true);
										if (
											application.proposal !== null &&
											application.proposal !== undefined
										) {
											navigate(
												`/hiring/${resourceRequest.id}/application/${application_id}/proposal/${application.proposal.id}`
											);
										} else {
											navigate(
												`/hiring/${resourceRequest.id}/application/${application_id}/proposal/new`
											);
										}
									}}
									className="comuna-orange-light-button flex flex-row font-medium whitespace-nowrap mt-4">
									<span>Edit offer</span>
								</button>

								<button
									onClick={() => {
										setSeeCosts(false);
									}}
									className="comuna-third-button flex flex-row font-medium whitespace-nowrap mt-4">
									<span>Go back</span>
								</button>
							</div>
						</div>,
						document.body
					)}
			</>
		);
	}

	return (
		<div
			id="comuna-popup-resource-request-panel"
			className={"comuna-popup open"}>
			{/** Close Button  */}
			<button
				onClick={Close}
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					background: "transparent",
					width: "100%",
					height: "100%",
					cursor: "default",
				}}></button>

			<div id="comuna-side-panel" className="comuna-side-panel pt-16 px-8 open" style={{ overflowX: "hidden" }}>

				{/* Close Button */}
				<button onClick={Close} className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
					<img src={xIcon} alt="icon of an x" />
				</button>

				<div className="w-8/12 w-full flex flex-col flex-1 bg-white rounded-xl mt-4 ml-2">
					<div className="flex flex-row top justify-start w-full h-fit mx-auto border-b-2">

						{/* Tab for Technical Interview */}
						{application.interview && application.interview.score > 0 &&
							<button
								onClick={() => setSelectedTab('interview')}
								className={`flex flex-row justify-center rounded-t-xl py-3 px-4 ${selectedTab === 'interview' ? 'border-b-4 border-comuna-purple' : ''}`}
							>
								<span className="centered-text text-sm font-semibold bg-white" style={{ verticalAlign: 'middle' }}>
									Technical Interview
								</span>
								{!application.interview.marked_view && <span className="comuna-primary-button xss ml-3">
									NEW
								</span>}
							</button>
						}

						{/* Tab for Profile */}
						<button
							onClick={() => setSelectedTab('profile')}
							className={`rounded-t-xl bg-white text-sm font-semibold py-3 px-4 text-center ${selectedTab === 'profile' ? 'border-b-4 border-comuna-purple' : ''}`}
						>
							Profile
						</button>

						{/* Tab for Messages */}
						<button
							onClick={() => setSelectedTab('messages')}
							className={`rounded-t-xl bg-white text-sm font-semibold py-3 px-4 text-center ${selectedTab === 'messages' ? 'border-b-4 border-comuna-purple' : ''}`}
						>
							Messages
						</button>

					</div>

					{/* Content based on selected tab */}
					<div className="w-full flex flex-col flex-1" style={{ overflow: "hidden", marginBottom: 110 }}>
						{selectedTab === 'profile' && <Profile />}
						{selectedTab === 'messages' && <MessagesComponent application={application} />}
						{selectedTab === 'interview' &&
							(interviewDetails ? (
								!interviewDetails.question_summary || interviewDetails.question_summary.length === 0 ? (
									<TechnicalInterviewComponent
										interview={interviewDetails}
										agent={interviewDetails.comuna_agent}
										contractor={interviewDetails.contractor}
										application={application}
									/>
								) : (
									<NewTechnicalInterviewComponent
										interview={interviewDetails}
										agent={interviewDetails.comuna_agent}
										contractor={interviewDetails.contractor}
										application={application}
									/>
								)
							) : (<Loader />))}
					</div>
				</div>
			</div>

			{CanShowActions() && (
				<div className="comuna-side-panel bottom flex flex-row p-6 open">
					{/** Reject Button */}
					{application.state > 1 ? (
						<Link
							to={"reject"}
							className="comuna-third-button flex flex-row font-medium whitespace-nowrap mr-4">
							<span>Reject</span>
						</Link>
					) : (
						<button className="comuna-disabled-button flex flex-row font-medium whitespace-nowrap mr-4">
							<span>Reject</span>
						</button>
					)}

					{/** Proposal Buttons */}
					{proposalExists() ? (
						<>
							{application.proposal.state === 1 ? (
								<button className="comuna-disabled-button flex flex-row font-medium whitespace-nowrap mr-4">
									<span>View offer</span>
								</button>
							) : (
								<button
									onClick={() => {
										navigate(
											`/hiring/${resourceRequest.id}/application/${application_id}/proposal/${application.proposal.id}`
										);
									}}
									className="comuna-orange-light-button flex flex-row font-medium whitespace-nowrap mr-4">
									<span>View offer</span>
								</button>
							)}
						</>
					) : (
						<button
							onClick={() => {
								navigate(
									`/hiring/${resourceRequest.id}/application/${application_id}/proposal/new`
								);
							}}
							className="comuna-secondary-button flex flex-row font-medium whitespace-nowrap mr-4">
							<span>{organizationDetails.direct_contract ? "Accept candidate" : "Submit offer"}</span>
						</button>
					)}

					{/** Interview Button */}
					{canAskForInterview ? (
						<Link
							to="interview"
							className="comuna-primary-button flex flex-row font-medium whitespace-nowrap">
							<span>Ask for interview</span>
						</Link>
					) : (
						<button className="comuna-disabled-button flex flex-row font-medium whitespace-nowrap mr-4">
							<span>Ask for interview</span>
						</button>
					)}
				</div>
			)}

			<Outlet />
		</div>
	);
}