import { useContext, useEffect, useState } from "react";
import {
	Link,
	useLoaderData,
	useRouteLoaderData,
	Outlet,
} from "react-router-dom";
import { BenefitItem } from "../components/BenefitItem";
import { UserContext } from "../Providers/UserContext";

import InvoiceState from "../components/InvoiceState";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import DashboardTransactionItem from "../components/DashboardTransactionItem";
import { Card, Label, Modal } from "flowbite-react";
import TransactionHistoryView from "../components/TransactionHistoryView";
import TransactionDetailView from "../components/TransactionDetailView";
import { WithdrawContext } from "../Providers/WithdrawContext";
import { ContractorProfilePicture } from "../components/ContractorProfilePicture";
import { RoleDescriptionView } from "../components/RoleDescriptionView";
import { copyToClipBoard } from "../utils/clipboard";
import {
	formatDateFromString,
	formatDateToLongString,
	formatStringDateFromServer,
} from "../utils/formaters";

import { Loader } from "../components/loader";
import { Benefits } from "../components/Benefits";
import { ActionButton } from "../components/ActionButton";

import { CardWithDateAndAmount } from "../components/CardWithDateAndAmount";

const paperIcon = require("../img/icons/paperIcon.png");
const fileIcon = require("../img/icons/file-icon.png");
const alertIcon = require("../img/icons/alert-icon.png");

const forwardIcon = require("../img/forwardIcon.png");
const paperIconNoBG = require("../img/copyIcon.png");
const warningIcon = require("../img/warningIcon.png");
const officeIcon = require("../img/icons/office.png");

//this comes from react router, the function that runs to load the info of this page.
export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const invoices = await ComunaAPI.GetInvoices(token);

	console.log("Result of Invoices", invoices);

	const paymentMethods = await ComunaAPI.GetPaymentMethods(token);
	return { invoices, paymentMethods };
}

export const ContractorDashboard: React.FC = () => {
	const { invoices, paymentMethods }: any = useLoaderData();

	//filtrate invoices with withdraw_method null
	const pendingInvoices = invoices.invoices.filter((invoice: any) => invoice.withdraw_method === null);
	const submitedInvoices = invoices.invoices.filter((invoice: any) => invoice.withdraw_method !== null);

	const { token, user }: any = useContext(UserContext);

	const { contracts }: any = useRouteLoaderData("contractor_root");

	const [contract, setContract] = useState<any>(
		contracts.length > 0 ? contracts[0] : null
	);

	const [copied, setCopied] = useState(false);

	const [showTransactionList, setShowTransactionList] = useState(false);
	const [showTransactionDetail, setShowTransactionDetail] = useState(false);
	const [showCreateInvoice, setShowCreateInvoice] = useState(false);

	const [selectedTransaction, setSelectedTransaction] = useState<any>();

	const currencySymbol = invoices.currency_symbol || "$";
	const currencyCode = invoices.currency_code || "USD";

	var transactionTemplate = {
		id: 8,
		client_contract: 0,
		invoice_details:
			"<table><tr> <td>Name</td> <td>Days worked</td> <td>Salary Amount</td> <td>Benefits Amount</td> <td>MUNA fee</td> <tr><tr><td>MarioC1 Ruiz</td><td>-4</td><td>-833</td><td>-640</td><td>-147</td></tr></table>",
		total_salary: -833,
		total_benefits: -640,
		comuna_fee: -147,
		payment_gateway: 0,
		date_created: "2023-10-19",
		date_payed: null,
		status: "created",
		payment_method: null,
		billing_cycle: "2023-10-30",
	};

	useEffect(() => {
		console.log("contracts: ", contracts);
		if (contracts.length === 0) {
			window.location.href = "/proposal";
		}

		setContract(contracts[0]);
		transactionTemplate.client_contract = contracts[0].id;
	}, []);

	function transactionSelected(transaction: any) {
		setSelectedTransaction(transaction);
		setShowTransactionDetail(true);
	}

	function approveTransaction(transaction: any) { }

	async function createTransaction(transaction: any) {
		console.log(
			"starting transaction creation, received client contract id: " +
			transaction.contract +
			" vs: " +
			contract.id
		);
		transaction.contract = contract.id;
		console.log(transaction);

		var noIssues = true;
		console.log("getting contracts");
		try {
			const newContract = await ComunaAPI.CreateInvoice(token, transaction);
			console.log(newContract);

			setShowCreateInvoice(false);
			window.location.reload();
		} catch (error) {
			noIssues = false;
			console.log("there was an error");
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
		}
	}

	function closeHistoryView() {
		setShowTransactionList(false);
	}

	function GetTotalForInvoice(invoice: any) {
		return (
			invoice.salary_amount +
			invoice.benefits_amount +
			invoice.one_time_payments
		).toLocaleString("en-US");
	}

	function ContractLength() {
		var start = new Date(contract.start_date);
		var end = new Date(contract.end_date);
		var months =
			(end.getFullYear() - start.getFullYear()) * 12 +
			(end.getMonth() - start.getMonth());
		return months + " months";
	}

	function CopyEmail() {
		copyToClipBoard(contract.client_email);
		setCopied(true);
		window.setTimeout(() => {
			setCopied(false);
		}, 2000);
	}

	if (contract == null) {
		return (
			<div className="flex justify-center items-center w-full">
				<Loader />
			</div>
		);
	}

	return (
		<WithdrawContext.Provider
			value={{
				paymentMethods,
			}}>
			<div className="flex flex-col w-full px-6 py-6">
				<label className="text-3xl font-semibold comuna-purple text-left mb-2">
					Top of the morning, {user.first_name}
				</label>

				<div className="flex flex-row w-full mt-5">
					{/** Contract role description */}

					<div className="flex flex-col w-full mr-4 pb-6">
						<div className="Upper flex flex-row w-full h-fit">
							<CardWithDateAndAmount
								label={"Last payment"}
								date={formatStringDateFromServer(invoices.last_payment.date)}
								amount={invoices.last_payment.amount}
								classname="mr-4"
								currency_symbol={currencySymbol}
								currency_code={currencyCode}
							/>
							<CardWithDateAndAmount
								label={"Next payment"}
								date={formatStringDateFromServer(
									invoices.upcoming_payments[0].date
								)}
								amount={invoices.upcoming_payments[0].amount}
								currency_symbol={currencySymbol}
								currency_code={currencyCode}
							/>
						</div>

						<div className="comuna-card flex flex-col w-full h-fit mt-3 mr-3 p-4">
							<div className="bg-purple-light w-full p-6 rounded-xl mb-4 flex flex-row">
								{/* Company Profile Picture. */}
								{contract.organization.profile_picture !== null && (
									<img
										src={contract.organization.profile_picture}
										style={{
											height: 148,
											width: 148,
											borderRadius: 32,
											overflow: "hidden",
										}}
										alt="Profile for current organization"
									/>
								)}
								{contract.organization.profile_picture == null && (
									<div
										className="bg-purple-dark flex justify-center items-center"
										style={{
											height: 148,
											width: 148,
											borderRadius: 32,
											overflow: "hidden",
										}}>
										{" "}
										<img
											src={officeIcon}
											alt="Profile for current organization"
										/>
									</div>
								)}
								{/** Company name and role details  */}
								<div className="flex flex-col justify-center flex-1">
									<label className="text-2xl font-bold black-text ml-4">
										{contract.organization.name}
									</label>
									<label className="text-md gray-black ml-4">
										{contract.role_name}
									</label>
								</div>
								<Link
									to="description"
									className="comuna-secondary-button sm font-bold self-center">
									Check role description
								</Link>
							</div>

							<div className="font-medium mb-2 px-1">Contact Information</div>
							<div className="comuna-field">
								<span>Client Email</span>
								<div className="flex flex-row justify-center">
									<b>{copied ? "copied" : contract.client_email}</b>
									{window.location.protocol === "http:" && (
										<button onClick={CopyEmail}>
											<img
												style={{ width: 24, height: 24, marginLeft: 7 }}
												src={paperIconNoBG}
												className="h-4 w-4"
												loading="lazy"
												alt="copy to clipboard"
											/>
										</button>
									)}
								</div>
							</div>

							<div className="font-medium mb-2 px-1">Contract</div>

							<div className="flex flex-row">
								{/* <div className="comuna-field mr-3">
										<span>Length</span>
										<b>{ContractLength()}</b>
									</div> */}
								<div className="comuna-field mr-3">
									<span>Start date</span>
									<b>{formatStringDateFromServer(contract.start_date)}</b>
								</div>
								<div className="comuna-field">
									<span>Hours</span>
									<b>{contract.monthly_hours}h / Month</b>
								</div>
							</div>

							<div className="flex flex-row">
								{/** TODO do we need to show the fdinal date at some point?  */}
								{/* <div className="comuna-field">
										<span>End date</span>
										<b>{contract.end_date}</b>
									</div> */}
							</div>

							<div className="flex flex-row">
								<div className="comuna-field mr-3">
									<span>Payment cadence</span>
									<b>{contract.payment_cadence}</b>
								</div>
								<div className="comuna-field">
									<span>Salary</span>
									<b>
										{currencySymbol}{" "}{contract.salary.toLocaleString("en-US")}{" "}{currencyCode} / year
									</b>								
								</div>
							</div>

							{contract.benefits.length > 0 && (
								<div>
									<div className="font-medium mt-4 mb-2 px-1">Benefits</div>
									<Benefits benefits={contract.benefits} />
								</div>
							)}
						</div>
					</div>

					<div className="flex flex-col w-2/3">
						{/** Send Invoices Buttons  */}

						{/** Input Payment info if no payment methods. */}
						{paymentMethods.length === 0 && (
							<ActionButton
								to={"/account/withdraw"}
								red={true}
								icon={alertIcon}
								title="Input your payment info"
								subtitle="Your earnings want to come home!"
								onClick={() => { }}
							/>
						)}

						{/** Approve an invoice if payment methods exists and billing dates are available  */}
						{paymentMethods.length > 0 &&
							pendingInvoices.length > 0 && (
								<ActionButton
									to="invoice/new"
									icon={paperIcon}
									title="Send Invoice"
									subtitle="We like to think of a cash register sound when you press this."
									onClick={() => { }}
								/>
							)}

						{/** My Contract button  */}
						<ActionButton
							to="contract"
							icon={fileIcon}
							title="My contract"
							subtitle="Let's check out the details of your contract."
							onClick={() => { }}
						/>

						{invoices.upcoming_payments.length > 0 && (
							<div className="flex flex-col comuna-card p-4 mt-2">
								<label className="text-sm black-text text-left pl-2 mb-4">
									Upcoming payments
								</label>
								{invoices.upcoming_payments.map((payment: any, i: number) => {
									return (
										<div
											key={i}
											className="p-2 pb-6 flex flex-row justify-between items-center"
											style={
												i < invoices.upcoming_payments.length - 1
													? {
														borderBottom: "2px solid #EFEFEE",
														marginBottom: 16,
													}
													: {}
											}>
											<div className="flex flex-col">
												<b className="font-medium">
													For {formatDateToLongString(payment.date)}
												</b>
												<b className="comuna-purple font-medium">
													{currencySymbol}{" "}
													{payment.amount
														.toLocaleString("en-US")
														.split(".")[0]}{" "}{currencyCode}
												</b>

											</div>
											<span className="orange-badge">Upcoming</span>
										</div>
									);
								})}
							</div>
						)}

						<div className="flex flex-col comuna-card p-4 mt-4">
							<div className="flex flex-row justify-between">
								<label className="text-sm black-text text-left pl-2 mb-4">
									Transaction history
								</label>
								<Link to={"invoices"} className="comuna-purple font-medium">
									See All
								</Link>
							</div>

							{submitedInvoices.length === 0 && (
								<div className="bg-purple-light p-6 rounded-xl">
									You have no transactions yet.
								</div>
							)}

							{submitedInvoices.map((invoice: any, index: number) => {
								if (index > 2) return null;
								return (
									<Link
										key={index}
										to={"invoices/" + invoice.id}
										className="bg-purple-light p-4 mb-4 rounded-xl flex flex-row justify-between items-center hover-card">
										<div className="flex flex-col">
											<b className="font-bold black-text text-sm">
												{invoice.organization}
											</b>
											<b className="font-medium black-text text-sm">
												For {formatDateToLongString(invoice.billed_from)} -{" "}
												{formatDateToLongString(invoice.billed_to)}
											</b>
											<b className="comuna-purple font-medium text-sm">
												{currencySymbol}{" "}{GetTotalForInvoice(invoice)}{" "}{currencyCode}
											</b>
										</div>
										{(invoice.status === "pending" || invoice.status === "created") && (
											<span className="orange-badge">Pending</span>
										)}
										{invoice.status === "processing" && (
											<span className="blue-badge">Processing</span>
										)}
										{invoice.status === "payed" && (
											<span className="green-badge">Payment sent</span>
										)}
										{invoice.status === "warning" && (
											<span className="red-badge">Warning</span>
										)}
									</Link>
								);
							})}
						</div>
					</div>
				</div>

				{showTransactionList && (
					<Modal show={showTransactionList}>
						<TransactionHistoryView
							transactions={submitedInvoices}
							onSelected={transactionSelected}
							close={() => {
								setShowTransactionList(false);
							}}
						/>
					</Modal>
				)}
				{showTransactionDetail && (
					<Modal show={showTransactionDetail} size="3xl">
						<TransactionDetailView
							contract={contract}
							editable={false}
							availableBillingDates={invoices.available_billing_dates}
							transaction={selectedTransaction}
							onApproved={approveTransaction}
							onCreate={createTransaction}
							onSelectedNewTransaction={transactionSelected}
							paymentMethods={paymentMethods}
							close={() => {
								setShowTransactionDetail(false);
							}}
						/>
					</Modal>
				)}
				{showCreateInvoice && (
					<Modal show={showCreateInvoice} size="3xl">
						<TransactionDetailView
							contract={contract}
							editable={true}
							availableBillingDates={invoices.available_billing_dates}
							transaction={transactionTemplate}
							onApproved={approveTransaction}
							onCreate={createTransaction}
							onSelectedNewTransaction={transactionSelected}
							paymentMethods={paymentMethods}
							close={() => {
								setShowCreateInvoice(false);
							}}
						/>
					</Modal>
				)}
			</div>
			<Outlet />
		</WithdrawContext.Provider>
	);
};
