import { DateTime } from "luxon";

export function ValidateRoles(roles:any[], setRoles:any):boolean{
    var passes = true; //general flag

    const newRoles = [...roles];

    newRoles.map((role) => {
        role.nameCheck = role.roleName !== "";
        role.descriptionCheck =
            role.roleDescription !== "" && role.roleDescription.length < 2049;
        role.skillsCheck = role.roleSkills.length > 0;
        // debugger;
        if (role.roleStartDate !== "") {
            var today = DateTime.now();
            var dateToArray = role.roleStartDate.split("-");
            // dateToArray.forEach((element: any) => {
            // 	console.log("element: ", element);
            // });
            if (dateToArray[1].length === 1) {
                dateToArray[1] = "0" + dateToArray[1];
            }
            if (dateToArray[2].length === 1) {
                dateToArray[2] = "0" + dateToArray[2];
            }
            // dateToArray.forEach((element: any) => {
            // 	console.log("element: ", element);
            // });
            var compundString =
                dateToArray[0] + "-" + dateToArray[1] + "-" + dateToArray[2];
            var date = DateTime.fromSQL(compundString);

            // Right now this is not being validated and doesn't hepl
            //role.dateGood = date > today;
            role.dateGood = true;
        } else {
            role.dateGood = false;
        }

        role.good =
            role.nameCheck &&
            role.descriptionCheck &&
            role.skillsCheck &&
            role.dateGood;
        //check if role startdate is in the future

        //if one role check does not pass we cant move forward
        if (!role.good) {
            passes = false;
        }
    });
    console.log("newRoles: ", newRoles);
    setRoles(newRoles);

    return passes;
}