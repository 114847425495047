import { Label, Modal, Radio } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { ContractorProfilePicture } from "./ContractorProfilePicture";
import { RoleDescriptionView } from "./RoleDescriptionView";
import { Loader } from "./loader";

interface ContractorSignContractProps {
	proposal: any;
	updateProposal: (proposal: any) => void;
	allSet: () => void;
}

const ContractorSignContract: React.FC<ContractorSignContractProps> = ({
	proposal,
	updateProposal,
	allSet,
}) => {
	const { token, user }: any = useContext(UserContext);

	const [contractText, setContractText] = useState("");

	const [currentPage, setCurrentPage] = useState<string>("detail");

	const [signature, setSignature] = useState("");
	const [printName, setPrintName] = useState("");
	const [signatureMatches, setSignatureMatches] = useState(true);

	const [showSignPopup, setShowSignPopup] = useState(false);

	const [showTaxPopup1, setShowTaxpayerPopup1] = useState(false);
	const [showTaxPopup2, setShowTaxpayerPopup2] = useState(false);
	const [showAllSet, setShowAllSet] = useState(false);

	const [contract, setContract] = useState<any>({});

	const [radioValue, setRadioValue] = useState("No");

	const checkIcon = require("../img/check.png");

	useEffect(() => {
		console.log("ContractorSignContract");
		getContractInfo();
	}, []);

	useEffect(() => {
		console.log("signature matches: " + signatureMatches);
	}, [signatureMatches]);

	function validateSignature() {
		console.log("validating Signature");
		var sigNotEmpty = signature.trim() !== "";
		var printNameNotEmpty = printName.trim() !== "";
		var sigMatch = sigNotEmpty && printNameNotEmpty;
	
		console.log(sigMatch);
		setSignatureMatches(sigMatch);
		if (sigMatch) {
		  setShowSignPopup(true);
		} else {
		  alert("Please ensure both fields are filled out and match exactly.");
		}
	  }
	const getContractInfo = async () => {
		var noIssues = true;

		try {
			const contractPreviewReq =
				await ComunaAPI.GetContractPreviewWithProposalId(token, proposal.id);
			console.log(contractPreviewReq);
			setContract(contractPreviewReq);
			setContractText(contractPreviewReq.contract);
		} catch (error) {
			noIssues = false;
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
		}
	};

	const ConfirmSignContract = async () => {
		if (radioValue !== "No") {
			return;
		}
		var noIssues = true;
		console.log("proposal detail: ");
		console.log(proposal.id);
		console.log("contract detail: ");
		console.log(contract.contract_id);
		console.log("signature detail: ");
		console.log(signature);
		console.log("print name detail: ");
		console.log(printName);
		var data = {
			proposal: proposal.id,
			contract: contract.contract_id,
			signature: signature,
			name: printName,
		};
		setShowAllSet(true); // what??

		try {
			const signContractReq = await ComunaAPI.SignContract(token, data);
			console.log(signContractReq);
			window.location.reload(); //what??
			var newProposal = proposal;
			newProposal.state = 1;
			updateProposal(newProposal);
			setShowAllSet(true);
			allSet();
			setShowTaxpayerPopup2(true);
		} catch (error) {
			noIssues = false;
			console.log("could not sign contract");
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
		}
	};

	function handleRadioChange(event: any) {
		console.log(event.target.value);
		setRadioValue(event.target.value);
	}

	return (
		<div className="w-full h-full">
			{showAllSet ? (
				<div className="flex flex-col w-full h-full p-5 text-center items-center">
					<div className="flex flex-col w-full h-full comuna-card p-5 text-center items-center">
						<div className="rounded-full w-40 h-40 items-center bg-comuna-purple justify-items-center p-12 my-5">
							{/* <div className="rounded-full w-full h-full justify-items-center"> */}
							<img src={checkIcon} className="w-full h-full"></img>
							{/* </div> */}
						</div>
						<label className="w-full text-2xl font-bold text-black pb-1 mb-5">
							You're all set!
						</label>

						<label className="text-xs text-gray-500 pb-1 px-5 mb-3">
							You are all set up, we will finish up your contract process and
							let you know when you are ready to start working and billing.
						</label>
					</div>
					<div className="flex flex-row w-full h-fit mt-4">
						<button
							onClick={() => {
								setShowTaxpayerPopup1(false);
								setShowTaxpayerPopup2(true);
								// ConfirmSignContract();
							}}
							className="comuna-primary-button w-full h-full  p-2  mr-1">
							{/* <label className="w-full text-sm text-center font-bold text-white p-1 cursor-pointer"> */}
							Got it
							{/* </label> */}
						</button>
					</div>
				</div>
			) : (
				<div className="w-full h-full">
					{currentPage === "description" ? (
						<div className="w-full h-full py-5">
							<RoleDescriptionView
								selectedContractor={{
									city_location: user.city_location,
									country_location: user.country_location,
									cv: user.cv,
									date_of_birth: user.date_of_birth,
									email: user.email,
									email_verified: user.email_verified,
									entity_name: user.entity_name,
									entity_type: user.entity_type,
									first_name: user.first_name,
									last_name: user.last_name,
									linkedin: user.linkedin,
									mailing_address: user.mailing_address,
									mailing_address_detail: user.mailing_address_detail,
									name: user.name,
									passport_id_number: user.passport_id_number,
									phone_country_indicator: user.phone_country_indicator,
									phone_number: user.phone_number,
									phone_verified: user.phone_verified,
									preferred_name: user.preferred_name,
									profile_picture: user.profile_picture,
									profile_type: user.profile_type,
									registered_as: user.registered_as,
									role_name: user.role_name,
									salary_expectation: user.salary_expectation,
									skills: user.skills,
									title: user.title,

									vat_id: user.vat_id,
									website: user.website,
									zip_code: user.zip_code,
									role_description: proposal.role_description,
								}}
								setPage={setCurrentPage}
							/>
						</div>
					) : (
						<div className="flex flex-col items-center w-full h-full p-5">
							{/* <div className="flex flex-col w-full h-full bg-slate-100 rounded-lg p-3 items-start"> */}
							<div className="flex flex-col w-full h-full comuna-card p-5 items-start mb-3">
								<ContractorProfilePicture contractor={user} />
								{contractText && <div>
								<div className="comuna-contract" dangerouslySetInnerHTML={{__html: contractText}}></div>

								<label className="w-full comuna-field pb-1 mt-5">Signature of beneficial owner</label>
								<input
									placeholder="Type your full name here"
									onChange={(e) => {
										console.log(e.target.value);
										setSignature(e.target.value);
									}}
									className={
										signatureMatches
											? "w-full h-10 comuna-input-italic px-2"
											: "w-full h-10 comuna-error px-2"
									}></input>

								<label className="comuna-field pb-1 mt-5">Print name of beneficial owner </label>
								<input
									placeholder="Type your full name here"
									onChange={(e) => {
										console.log(e.target.value);
										setPrintName(e.target.value);
									}}
									className={
										signatureMatches
											? "w-full h-10 comuna-input-italic px-2"
											: "w-full h-10 comuna-error px-2"
									}></input>
								</div>}
								{!contractText && <div> Loading Contract... </div>}
							</div>

							<div className="flex flex-row w-full h-ful rounded-md mt-2">
								<button
									onClick={validateSignature}
									className="comuna-primary-button w-full p-3 mr-1">
									{/* <label className="w-full text-sm text-center font-bold text-white pb-1">
								Review Details and Sign the Contract
							</label> */}
									Review Details and Sign the Contract
								</button>
							</div>
							<Modal size="4xl" show={showSignPopup} onClose={() => {}}>
								<div className="flex flex-col w-full h-full  p-5 items-start">
									<label className="w-full text-2xl font-bold text-black pb-1 text-center">
										Confirm your details before signing the contract
									</label>
									<label className="w-full text-sm text-gray-700 pb-1 text-center mt-4 mb-5">
										Double check the details, before it gets harder to change.
									</label>
									<label className="comuna-field pb-1">
										Signature of beneficial owner
									</label>
									<label className="comuna-input p-2 w-full">{signature}</label>
									<label className="comuna-field pb-1 mt-3">
										Print name of beneficial owner
									</label>
									<label className="comuna-input p-2 w-full">
										{" "}
										{printName}
									</label>

									<div className="flex flex-row w-full h-fit mt-5 mb-2">
										<button
											onClick={() => {
												setShowSignPopup(false);
											}}
											className="comuna-secondary-button w-full h-full p-2 mr-1">
											{/* <label className="w-full text-sm text-center font-bold text-blue-500 p-1 cursor-pointer"> */}
											Edit Details
											{/* </label> */}
										</button>
										<button
											onClick={() => {
												setShowSignPopup(false);
												setShowTaxpayerPopup1(true);
												// ConfirmSignContract();
											}}
											className="comuna-primary-button w-full h-full  p-2 mr-1">
											<label className="w-full text-sm text-center font-bold text-white p-1 cursor-pointer">
												Confirm and Sign Contract
											</label>
										</button>
									</div>
								</div>
							</Modal>
							<Modal size="lg" show={showTaxPopup1} onClose={() => {}}>
								<div className="flex flex-col w-full h-full p-5 text-center">
									<label className="w-full text-2xl font-extrabold text-black pb-1 mb-5">
										I am a non-US Contractor / Taxpayer
									</label>

									<label className="text-sm text-gray-500 pb-1 px-5 mb-3">
										MUNA will collect the necessary information to generate a
										W-8BEN which justifies you not being US citzen and therefore
										noy paying US taxes. This document is required for your
										client. Please confirm below that you are not a US
										contractor.
									</label>
									<div className="flex flex-row w-full h-fit mt-4">
										<button
											onClick={() => {
												setShowTaxpayerPopup1(false);
												setShowTaxpayerPopup2(true);
												// ConfirmSignContract();
											}}
											className="comuna-primary-button w-full h-full  p-2 mr-1">
											{/* <label className="w-full  p-1 cursor-pointer"> */}
											Confirm
											{/* </label> */}
										</button>
									</div>
									<div className="flex flex-row w-full h-fit mt-4 items-start">
										<label className=" text-xs text-start text-gray-500 p-1 cursor-pointer">
											Are you a US Contractor?
										</label>
										<label className="text-xs text-start comuna-purple p-1 cursor-pointer">
											Update your account settings details
										</label>
									</div>
								</div>
							</Modal>
							<Modal size="lg" show={showTaxPopup2} onClose={() => {}}>
								<div className="flex flex-col w-full h-full py-5 px-10 text-center">
									<label className="w-full text-2xl font-bold text-black pb-1 mb-5">
										PART 2. Claim of tax treaty
									</label>

									<label className="text-xs text-gray-500 pb-1 px-5 mb-3">
										During the tax year (6th April 2023 - 5th April 2024) will
										you have been in the United States of America for 183 days
										or more?
									</label>
									<fieldset className="flex max-w-md flex-col gap-4" id="radio">
										<div
											className="flex items-center gap-2 w-fit"
											onChange={handleRadioChange}>
											<Radio
												id="yes"
												name="YesNo"
												value="Yes"
												className="w-full"
												// onChange={() => {
												// 	console.log("changed");

												// }}
											/>

											<label htmlFor="Yes">Yes</label>
										</div>
										<div
											className="flex items-center gap-2 w-fit"
											onChange={handleRadioChange}>
											<Radio
												defaultChecked
												id="no"
												name="YesNo"
												value="No"
												className="w-full"
											/>
											<label htmlFor="No">No</label>
										</div>
									</fieldset>
									<div className="flex flex-row w-full h-fit mt-4">
										<button
											onClick={() => {
												ConfirmSignContract();
											}}
											className={
												radioValue === "Yes"
													? "comuna-disabled-button w-full h-full  p-2 mr-1"
													: "comuna-primary-button w-full h-full  p-2 mr-1"
											}>
											Confirm
										</button>
									</div>
									<div className="flex flex-row w-full h-fit mt-4 items-start">
										<label className=" text-xs text-start text-gray-500 p-1 cursor-pointer">
											Are you a US Contractor?
										</label>
										<label className="text-xs text-start comuna-purple p-1 cursor-pointer">
											Update your account settings details
										</label>
									</div>
									<div className="flex flex-row w-full h-fit mt-4 items-start"></div>
								</div>
							</Modal>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ContractorSignContract;
