import { Button } from "flowbite-react";
import Select, { MultiValue, SingleValue } from "react-select";
import { CountryInput, StateInput } from "../CountryInput";
import React, { ChangeEvent, useEffect, useState } from "react";

import ContractorOnboardingTracker from "../ContractorOnboardingTracker";

interface ContractorMailAddressProps {
	nextStep: () => void;
	prevStep: () => void;
	sendContractorMailAddressInfo: (
		address: string,
		details: string,
		country: string,
		city: string,
		state: string,
		zipcode: string
	) => void;
}

const ContractorMailAddressForm: React.FC<ContractorMailAddressProps> = ({
	nextStep,
	prevStep,
	sendContractorMailAddressInfo,
}) => {
	const [mailAddress, setMailAddress] = useState<string>("");
	const [mailDetails, setMailDetails] = useState<string>("");
	const [mailCountry, setMailCountry] = useState<string>("");
	const [mailState, setMailState] = useState<string>("");
	const [mailCity, setMailCity] = useState<string>("");
	const [zipCode, setZipCode] = useState<string>("");

	const [addressOk, setAddressOk] = useState<boolean>(true);
	const [detailsOk, setDetailsOk] = useState<boolean>(true);
	const [countryOk, setCountryOk] = useState<boolean>(true);
	const [stateOk, setStateOk] = useState<boolean>(true);
	const [cityOk, setCityOk] = useState<boolean>(true);
	const [zipOk, setZipOk] = useState<boolean>(true);

	const [countryOptions, setCountryOptions] = useState([
		{
			value: "Select Country",
			label: "Select Country",
		},
	]);
	const [stateOptions, setStateOptions] = useState([
		{
			value: "Select State",
			label: "Select State",
		},
	]);

	const inputValid = "comuna-input";
	const inputWarning = "comuna-error";

	const leftArrow = require("../../img/leftArrow.png");

	function changeMailAddress(e: ChangeEvent<HTMLInputElement>) {
		setMailAddress(e.target.value);
	}

	function changeMailDetails(e: ChangeEvent<HTMLInputElement>) {
		setMailDetails(e.target.value);
	}

	function changeMailCity(e: ChangeEvent<HTMLInputElement>) {
		setMailCity(e.target.value);
	}

	function changeZipCode(e: ChangeEvent<HTMLInputElement>) {
		setZipCode(e.target.value);
	}

	function checkFlags() {
		setAddressOk(mailAddress.trim() !== "");
		setDetailsOk(mailDetails.trim() !== "");
		setCountryOk(mailCountry !== "Select Country" && mailCountry.trim() !== "");
		setStateOk(mailState !== "Select State" && mailState.trim() !== "");
		setCityOk(mailCity.trim() !== "");
		setZipOk(zipCode.trim() !== "");
	}

	function startSendMailInfo() {
		// First check which fields are empty
		checkFlags();

		// If all flags are true, then proceed
		if (addressOk && detailsOk && countryOk && stateOk && cityOk && zipOk) {
			sendContractorMailAddressInfo(
				mailAddress,
				mailDetails,
				mailCountry,
				mailCity,
				mailState,
				zipCode
			);
			nextStep();
		} else {
			// Otherwise, show an alert
			alert("Please fill out all required fields before continuing.");
		}
	}

	return (
		<div className="flex flex-row w-full h-full">
			<div className="rightBlock flex flex-col items-start w-full h-full pb-16 bg-white">
				<div className="flex flex-col w-9/12 h-full self-center mt-16">
					<div className="flex flex-col w-full h-full">
						<div className="rightBlock w-full h-full">
							<div
								className="backButton flex flex-row h-16 h-fit cursor-pointer mb-10"
								onClick={() => prevStep()}
							>
								<img className="mx-2 cursor-pointer w-4 h-4" src={leftArrow} />
								<label className="text-sm font-bold comuna-purple cursor-pointer">
									Back
								</label>
							</div>

							<ContractorOnboardingTracker step={4} />

							<div className="flex flex-col mb-16">
								<label className="text-lg font-bold">Address</label>
								<label className="text-xs text-slate-600">
									Please provide your personal address.
								</label>
							</div>

							<div className="w-full h-fit">
								<div className="flex flex-col w-full bg-white rounded-lg">
									<label className="text-gray-500">Address line 1</label>
									<input
										type="text"
										className={
											(addressOk ? inputValid : inputWarning) +
											" rounded-md mb-2 shadow-sm"
										}
										placeholder="Line 1"
										value={mailAddress}
										onChange={changeMailAddress}
									/>
								</div>

								<div className="flex flex-col w-full bg-white rounded-lg">
									<label className="text-gray-500">Address line 2</label>
									<input
										type="text"
										className={
											(detailsOk ? inputValid : inputWarning) +
											" rounded-md mb-2 shadow-sm"
										}
										placeholder="Line 2"
										value={mailDetails}
										onChange={changeMailDetails}
									/>
								</div>

								<div>
									<div className="flex flex-row w-full">
										<div className="w-full h-full mr-1">
											<div className="flex flex-col w-full bg-white rounded-lg">
												<label className="text-gray-500">Country</label>
												<CountryInput
													onChange={(v) => {
														setMailCountry(v);
													}}
												/>
											</div>
										</div>
										<div className="w-full h-full ml-1">
											<div className="flex flex-col w-full bg-white rounded-lg">
												<label className="text-gray-500">State/Province</label>
												<StateInput
													onChange={(v) => {
														setMailState(v);
													}}
													country={mailCountry}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="flex flex-row w-full mt-2">
									<div className="w-full h-full mr-1">
										<div className="flex flex-col w-full bg-white rounded-lg">
											<label className="text-gray-500">City</label>
											<input
												type="text"
												className={cityOk ? inputValid : inputWarning}
												value={mailCity}
												onChange={changeMailCity}
											/>
										</div>
									</div>
									<div className="w-full h-full ml-1">
										<div className="flex flex-col w-full bg-white rounded-lg">
											<label className="text-gray-500">ZIP/Postal code</label>
											<input
												type="text"
												className={zipOk ? inputValid : inputWarning}
												value={zipCode}
												onChange={changeZipCode}
											/>
										</div>
									</div>
								</div>
							</div>

							<button
								className="comuna-primary-button mt-6"
								onClick={startSendMailInfo}
							>
								Complete Profile
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractorMailAddressForm;